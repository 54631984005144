<template>
  <div class="flex flex-col">
    <div class="flex bg-gray-200 p-4 mb-6 rounded-lg">
      <input
        class="py-1 px-2 w-3/4 rounded-sm text-lg"
        placeholder="Search for a lead, email, phone number..."
        type="search"
        name=""
        id=""
        v-model="search"
      />
      <div class="flex ml-4 w-1/4 gap-4">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="relative inline-block text-left">
          <div>
            <button
              type="button"
              class="
                inline-flex
                justify-between
                w-36
                rounded-md
                shadow-sm
                px-6
                py-2
                bg-white
                text-sm
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-offset-gray-100
                focus:ring-indigo-500
              "
              id="menu-button"
              :aria-expanded="this.statusButtonExpanded"
              aria-haspopup="true"
              @click="toggleStatusButton"
            >
              {{ this.status.charAt(0).toUpperCase() + this.status.slice(1) }}
              <!-- Heroicon name: solid/chevron-down -->
              <svg
                class="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1
                0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>

          <!--
            Dropdown menu, show/hide based on menu state.

            Entering: "transition ease-out duration-100"
              From: "transform opacity-0 scale-95"
              To: "transform opacity-100 scale-100"
            Leaving: "transition ease-in duration-75"
              From: "transform opacity-100 scale-100"
              To: "transform opacity-0 scale-95"
          -->

          <div
            class="
              origin-top-right
              absolute
              right-0
              mt-2
              w-56
              rounded-md
              shadow-lg
              bg-white
              ring-1 ring-black ring-opacity-5
              focus:outline-none
            "
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            v-if="statusButtonExpanded"
            tabindex="-1"
          >
            <div class="py-1" role="none">
              <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
              <a
                href="#"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-2"
                @click="changeFilterStatus('all')"
                >All</a
              >
              <a
                href="#"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-0"
                @click="changeFilterStatus('confirmed')"
                >Confirmed</a
              >
              <a
                href="#"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-1"
                @click="changeFilterStatus('pending')"
                >Pending</a
              >
              <a
                href="#"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="menu-item-2"
                @click="changeFilterStatus('failed')"
                >Failed</a
              >
            </div>
          </div>
        </div>
        <button
          class="
            group
            relative
            w-full
            flex
            justify-center
            py-2
            px-4
            border border-transparent
            text-sm
            font-medium
            rounded-md
            text-white
            bg-yellow-500
            hover:bg-indigo-700
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          "
          @click="exportCSV"
        >
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <svg
              class="h-5 w-5 text-yellow-400 group-hover:text-indigo-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0
                  01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
          Export
        </button>
      </div>
    </div>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="table-auto min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-for="(title, index) in cols"
                  :key="index"
                  scope="col"
                  class="
                    px-6
                    py-3
                    text-left text-xs
                    font-medium
                    text-gray-500
                    uppercase
                    tracking-wider
                  "
                >
                  {{ title }}
                </th>
              </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200 text-left">
              <tr
                v-for="log in filteredLeads"
                :key="log._id"
                class="hover:bg-gray-100"
              >
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img class="h-10 w-10 rounded-full" src="/img/user.png" alt="" />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900 capitalize">
                        {{ log.name.first }} {{ log.name.last }}
                      </div>
                      <div class="text-sm text-gray-500">{{ log.email }}</div>
                    </div>
                  </div>
                </td>

                <td class="px-6 py-4 whitespace-nowrap">
                  <span
                    class="px-2 inline-flex text-xs capitalize leading-5 font-semibold rounded-full"
                    :class="{
                      'bg-yellow-100': log.status == 'pending',
                      'text-yellow-800': log.status == 'pending',
                      'bg-green-100': log.status == 'confirmed',
                      'text-green-800': log.status == 'confirmed',
                      'bg-red-100': log.status == 'failed',
                      'text-red-800': log.status == 'failed',
                    }"
                  >
                    {{ log.status }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div class="text-sm text-gray-800 capitalize font-semibold">
                    {{ log.package }}
                  </div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ log.did }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ parseDate(log.updatedAt) }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-left text-sm font-medium">
                  <a v-if="log.link" :href="log.link" class="text-indigo-600 hover:underline"
                    >Activate Account</a
                  >
                </td>
              </tr>

              <!-- More people... -->
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cols: [String],
    data: [Object],
  },
  data() {
    return {
      search: '',
      status: 'all',
      statusButtonExpanded: false,
    };
  },
  methods: {
    parseDate(timestamp) {
      const lastUpdated = new Date(timestamp);
      const date = lastUpdated.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      });

      const time = lastUpdated.toLocaleTimeString('en-US', {
        timeZone: 'America/New_York',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
      });
      return `${date} @ ${time}`;
    },
    toggleStatusButton() {
      this.statusButtonExpanded = !this.statusButtonExpanded;
    },
    changeFilterStatus(status) {
      this.status = status;
      this.statusButtonExpanded = false;
    },
    exportCSV() {
      const CSV_COLUMNS = [
        'Name',
        'Email',
        'Company',
        'Status',
        'Package',
        'DID',
        'Note',
        'Created',
        'Updated',
      ];
      const CSV_DELIMETER = ',';
      const header = `${CSV_COLUMNS.join(CSV_DELIMETER)}\n`;
      let csv = header;

      this.filteredLeads.forEach((lead) => {
        let row = '';
        CSV_COLUMNS.forEach((title) => {
          const key = title.toLowerCase();
          let val = lead[key];

          if (key === 'name') {
            val = `${lead.name.first} ${lead.name.last}`;
          }

          if (key === 'created') {
            val = `${lead.createdAt}`;
          }
          if (key === 'updated') {
            val = `${lead.updatedAt}`;
          }

          row += `${val}${CSV_DELIMETER}`;
        });

        csv += `${row}\n`;
      });

      const csvData = new Blob([csv], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvData);

      const hiddenElement = document.createElement('a');
      hiddenElement.href = csvUrl;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'leads.csv';
      hiddenElement.click();
    },
  },
  computed: {
    filteredLeads() {
      const regEx = new RegExp(this.search, 'i');
      return this.data.filter(
        (lead) => (regEx.test(lead.name.first)
            || regEx.test(lead.name.last)
            || regEx.test(lead.email)
            || regEx.test(lead.did))
          && lead.status.match(this.status === 'all' ? '' : this.status),
      );
    },
  },
};
</script>

<style></style>
