<template>
    <h2
      class="text-left mb-8 lg:text-4xl font-bold leading-7 text-gray-900 sm:text-3xl"
    >
      Telidesk Customer Signup
    </h2>
  <base-table v-if="logs" :cols="leadCols" :data="this.logs"/>
</template>

<script>
import { mapState } from 'vuex';
import store from '@/store';
import BaseTable from '@/components/BaseTable.vue';

export default {
  setup() {
    store.dispatch('getLogs');
  },
  computed: {
    ...mapState(['logs']),
    leadCols() {
      return ['Lead', 'Status', 'Package', 'DID', 'Last Updated', 'Actions'];
    },
  },

  components: {
    BaseTable,
  },
};
</script>

<style></style>
